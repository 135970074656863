.wallet-adapter-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: -4px;
}

.wallet-adapter-modal-menu {
    border-right: 0px;
}

.wallet-adapter-modal-menu > .ant-menu-submenu {
    display: flex;
    flex-direction: column-reverse;
}

.wallet-adapter-modal-menu .ant-menu-submenu-arrow {
    right: 18px;
}

.wallet-adapter-modal-menu .ant-menu-submenu-title {
    margin: 0;
}

.wallet-adapter-modal-menu .ant-menu-submenu-title:hover,
.wallet-adapter-modal-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: white;
}

.wallet-adapter-modal-menu .ant-menu-submenu-title > .ant-menu-title-content {
    padding-left: 25px;
}

.wallet-adapter-modal-menu .wallet-adapter-modal-menu-item {
    margin: 0px;
    padding: 0px;
    height: 44px;
    line-height: 44px;
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
}

.wallet-adapter-modal-menu .wallet-adapter-modal-menu-item:not(:last-child) {
    margin-bottom: 0px;
}

.wallet-adapter-modal-menu-button {
    height: 44px;
    padding-left: 24px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.wallet-adapter-modal-menu-button-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.wallet-adapter-multi-button-menu {
    padding: 0px;
    margin-top: -44px;
}

.wallet-adapter-multi-button-menu-item {
    padding: 0px;
}

.wallet-adapter-multi-button-menu-button {
    text-align: left;
}

.wallet-adapter-multi-button-icon {
    font-size: 20px;
    margin-right: 12px;
}

.wallet-adapter-multi-button-item {
    padding: 0px;
    padding-left: 12px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}
