body,
html {
  height: auto;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  color: #fff;
  background: #0b2a2f;
  background: linear-gradient(135deg, #0b2a2f 0%, #003d47 100%);
}
html {
  background: #0b2a2f;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.01em;
}

.candy-stat-component-title,
.candy-title {
  font-family: 'Bebas Neue';
  letter-spacing: 0.03em;
}

a,
.candy-value a {
  color: #fff;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.candy-value a {
  color: #fff !important;
  &:hover {
    color: #fff !important;
    text-decoration: underline;
  }
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  margin-bottom: 48px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .main-header-logo {
    max-width: 130px;
    width: 100%;
    height: 74px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.ant-btn {
  border-radius: 20px;
}

.main-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 350px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-image: url('https://storage.googleapis.com/cdn.solice.io/public/solice-map-gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
  > div {
    padding-left: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.main-banner-title {
  font-family: 'Bebas Neue';
  font-size: 42px;
  letter-spacing: 0.03em;
  text-align: left;
}

.main-banner-description {
  font-size: 16px;
  text-align: left;
  margin-bottom: 12px;
}

.main-banner-button {
  display: inline-flex;
  position: relative;
  border: 1px solid #2d3085;
  appearance: none;
  padding: 13px 34px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Bebas Neue';
  height: auto;
  font-size: 16px;
  letter-spacing: 0.02em;
  background: linear-gradient(90deg, rgb(69, 180, 212), rgb(86, 78, 216));
  box-shadow: 1px 2px 16px 3px rgba(54, 54, 54, 0.5);

  &:hover {
    background: linear-gradient(90deg, rgb(77, 200, 235), rgb(97, 89, 240));
    color: #fff;
    border: 1px solid #2d3085;
    text-decoration: none!important;
  }

  &:active,
  &:focus {
    background: linear-gradient(90deg, rgb(65, 173, 202), rgb(79, 73, 202));
    color: #fff;
    border: 1px solid #2d3085;
  }

  &:disabled {
    background: linear-gradient(90deg, hsl(193deg 2% 25%), hsl(243deg 4% 38%));
    border-color: hsl(193deg 2% 25%);
    cursor: unset;
  }
}

.footer-container {
  padding: 100px 24px;
  background-image: linear-gradient(
    133deg,
    #112a4e 0%,
    #002d43 51%,
    #031b53 100%
  );
  box-shadow: rgb(0 0 0 / 60%) -8px 20px 14px -17px inset;
}

.footer-inner {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 24px;
  @media (max-width: 480px) {
    grid-template-columns: auto;
    justify-content: center;
    text-align: center;
    grid-gap: 48px;
  }
}

.footer-logo {
  display: block;
  max-width: 178px;
  width: 100%;
  height: 74px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.footer-heading {
  margin: 0;
  margin-bottom: 24px;
  font-size: 24px;
  font-family: 'Bebas Neue';
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;
}

.footer-link {
  display: block;
  font-size: 16px;
  font-family: 'Bebas Neue';
  line-height: 1;
  letter-spacing: 0.01em;
  color: #bfbfbf;
  &:hover {
    text-decoration: underline;
    color: #fff;
  }
}

.footer-link + .footer-link {
  margin-top: 12px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 24px;
  padding-top: 24px;
  @media (max-width: 680px) {
    flex-direction: column;
  }
}

.header-logo {
  max-width: 130px;
  width: 100%;
  height: 74px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.header-nav {
  display: flex;
  grid-gap: 48px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 680px) {
    margin-top: 24px;
  }
}

.header-navLink {
  position: relative;
  font-size: 20px;
  font-family: 'Bebas Neue';
  line-height: 1;
  letter-spacing: 0.01em;
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.ant-btn-primary {
  display: inline-flex;
  position: relative;
  border: 1px solid #2d3085;
  appearance: none;
  padding: 13px 34px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Bebas Neue';
  height: auto;
  font-size: 16px;
  letter-spacing: 0.02em;
  background: linear-gradient(90deg, rgb(69, 180, 212), rgb(86, 78, 216));
  box-shadow: 1px 2px 16px 3px rgba(54, 54, 54, 0.5);

  &:hover {
    background: linear-gradient(90deg, rgb(77, 200, 235), rgb(97, 89, 240));
    border: 1px solid #2d3085;
  }

  &:active,
  &:focus {
    background: linear-gradient(90deg, rgb(65, 173, 202), rgb(79, 73, 202));
    border: 1px solid #2d3085;
  }

  &:disabled {
    background: linear-gradient(90deg, hsl(193deg 2% 25%), hsl(243deg 4% 38%));
    border-color: hsl(193deg 2% 25%);
    cursor: unset;
  }
}

.candy-label {
  color: #4f7bd6 !important;
}

.candy-button {
  display: inline-flex !important;
  position: relative !important;
  appearance: none !important;
  padding: 13px 34px !important;
  color: #fff !important;
  cursor: pointer!important;
  height: auto!important;
  letter-spacing: .02em!important;
  background: linear-gradient(90deg, #45b4d4, #564ed8)!important;
  border: 1px solid #2d3085!important;
  border-radius: 10px!important;
  padding: 13px 34px!important;
  font-family: Bebas Neue!important;
  font-size: 16px!important;
  box-shadow: 1px 2px 16px 3px #36363680!important;

  &:hover {
    background: linear-gradient(90deg, rgb(77, 200, 235), rgb(97, 89, 240))!important;
    color: #fff;
    border: 1px solid #2d3085!important;
    text-decoration: none!important;
  }
}

.candy-dropdown {
  background-color: transparent !important;
  padding: 12px !important;
  border: 1px solid #14535e !important;
  width: 184px !important;
  &:hover {
    cursor: pointer !important;
  }
  border-radius: 4px !important;
  position: relative !important;
}

.candy-dropdown-flex {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.candy-dropdown-menu {
  width: 184px !important;
  background-color: #05424d !important;
  margin-top: 4px !important;
  position: absolute !important;
  left: 0 !important;
  top: 100% !important;
  z-index: 99 !important;
  border: 1px solid #031f25 !important;
  border-radius: 4px !important;
  .menu-middle-item {
    padding: 12px !important;
    border-bottom: 1px solid #03272e !important;
    text-align: left !important;
  }
  .menu-middle-item:hover,
  .menu-last-item:hover {
    background-color: #1b7988 !important;
  }
  .menu-last-item {
    padding: 12px !important;
    text-align: left !important;
  }
}

.candy-dropdown-label {
  color: #fff;
}
.candy-dropdown-label--black {
  color: #fff !important;
}
.candy-dropdown-label--purple {
  color: #fff !important;
}
.candy-dropdown-icon path {
  fill: #fff !important;
}

.candy-nft-card {
  border-radius: 16px !important;
  height: auto !important;
  position: relative !important;
  z-index: 3 !important;
  background-color: rgba(171, 171, 171, 0.36) !important;
  overflow: hidden !important;
  border: 2px solid black !important;
}
.candy-order-info {
  color: #fff !important;
}

.candy-order .candy-order-name {
  color: #fff!important;
}
.candy-order .candy-order-price {
  color: #fff!important;
}
.candy-order .candy-order-ticker {
  color: #fff!important;
}
.candy-nft-info {
  color: #fff !important;
  vertical-align: middle !important;
  padding: 12px !important;
  background-color: #072d34 !important;
  .name {
    white-space: pre-wrap !important;
  }
  .ticker {
    font-size: 14px !important;
    text-align: left !important;
    font-weight: 400 !important;
    height: 22px !important;
  }
}

.candy-order {
  height: 100% !important;
  border-radius: 16px !important;
  border: 2px solid #021012 !important;
  background-color: rgba(171, 171, 171, 0.36) !important;
  overflow: hidden !important;
}

.candy-order-info {
  background-color: #072d34 !important;
  .name {
    white-space: pre-wrap !important;
  }
  .ticker {
    font-size: 14px !important;
    text-align: left !important;
  }
}

.candy-order-price-container {
  align-self: flex-end !important;
  text-align: right !important;
  .text {
    color: #b5b5b5 !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
  }
  .price {
    font-size: 14px !important;
    font-weight: bold !important;
  }
}

.candy-sell-modal-input-number {
  width: 100% !important;
  height: 40px !important;
  padding: 4px 8px !important;
  display: flex !important;
  align-items: center !important;

  border-radius: 4px !important;
  border: 2px solid #bdbdbd !important;

  input {
    border: none !important;
    outline: none !important;
    flex-grow: 1 !important;
    background: transparent !important;

    &::placeholder {
      color: #687c7f !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield !important;
    }
  }
}

.candy-modal-content {
  background: #0b2a2f;
  background: linear-gradient(135deg, #0b2a2f 0%, #003d47 100%);
  color: #fff !important;
  border-color: #076271 !important;
}

.candy-attribute-container .candy-nft-attribute {
  background-color: #49a0d5 !important;
}
.candy-modal-close-btn .close:before,
.candy-modal-close-btn .close:after {
  background-color: #fff !important;
}

.candy-sell-modal-button {
  display: block !important;
}

.candy-status-tag {
  background: linear-gradient(90deg, #45b4d4, #564ed8)!important;
  left: 0!important;
  top: 14px!important;
  border-top-right-radius: 4px!important;
  border-bottom-right-radius: 4px!important;
  &:after {
    display: none!important;
  }
}

.candy-loading:after {
  background-color: #07333b!important;
}

.candy-nft-info {
  border-radius: 0!important;
}

.ant-modal-content {
  background: #0b2a2f;
  color: #fff;
  border-radius: 8px;
  background: linear-gradient(135deg, #0b2a2f 0%, #003d47 100%);
}

.ant-menu, .ant-modal-header {
  background-color: transparent;
  color: #fff;

}
.ant-modal-header {
  border-bottom: 1px solid #254a51;
  color: #fff;
}

.ant-modal-title {
  color: #fff;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.ant-modal-close-x {
  color: #fff;
}

.ant-menu-submenu-arrow {
  color: #fff;
}

.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-title-content button {
  color: #fff;
  &:hover {
    color: #1890ff;
  }
}
.ant-menu-title-content img {
  margin-left: 0;
  margin-right: 8px;
}


.candy-order:hover, .candy-nft-card:hover {
   cursor: pointer;
}


.screen-control {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  grid-gap: 8px;
  margin-bottom: -51px;
  @media (max-width: 1040px) {
   margin-bottom: 24px;
  }
}

.screen-control-button {
  text-align: center;
  padding: 12px 32px;
  font-size: 18px;
  border: 1px solid #207988;
  border-radius: 4px;
  font-family: 'Bebas Neue';
  cursor: pointer;
  &:hover {
    background-color: #0c3e47;
  }
  &.active {
    background-color: #0f4d58;
  }
}

.screen-control-auction {
  justify-content: center;
  margin-bottom: 24px!important;
}

.screen-control-auction .screen-control-button {
  padding: 0;
  border: 0;
  background-color: transparent!important;
  &.active {
  text-decoration: underline;
  }
}

.candy-orders-filter > .candy-filter .candy-filter-title {
  font-family: 'Bebas Neue';
  font-size: 22px!important;
  letter-spacing: 0.03em;
  border: 0!important;
  padding-bottom: 0!important;
}

.candy-orders-filter > .candy-filter ul li.selected {
  color: #49d7ff!important;
  background-color: transparent!important;
}

.candy-card-info {
  background-color: #072d34!important;
  color: #fff!important;
  .ticker {
    color: #fff!important;
  }
}

@media (max-width: 450px) {
  .screen-control {
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .screen-control > .screen-control-button {
    margin-top: 8px;
  }
  .screen-control > .screen-control-button:first-child {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .candy-buy-modal {
    display: block!important;
  }
  .candy-buy-modal .candy-buy-modal-thumbnail {
    width: 200px!important;
    height: 200px!important;
  }
}

.candy-filter-selected-name {
  border-color: #fff!important;
  color: #fff!important;
}

.candy-activity .candy-activity-header span {
  color: #fff!important;
  border-bottom-color: #fff!important;
}

.candy-activity .candy-activity-item img {
  background-color: transparent!important;
}
.candy-activity {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 0!important;
  padding-right: 0!important;
}
@media only screen and (max-width: 1600px) {
  .candy-activity .candy-activity-item {
    grid-template-columns: minmax(250px, 1fr) 150px 150px 150px 150px !important;
  }
}

.candy-input-price input {
  background: transparent;
}